import { FC, useCallback, useMemo } from 'react'
import { Icon, IconButton, Table } from '@wmgtech/legato'

import { ROLES, useUsers } from 'modules/Users'
import { ColumnsType, IUser, IUserForm, ModalForm, useAdmin } from 'modules/Admin'
import { Pagination } from 'components/Pagination'
import { SearchField } from 'components/Fields'
import { ActionButton } from "components/ActionButton"
import { ConditionRender, SkeletonTable } from "components"
import ActivationCode from "../../../Admin/components/ActivationUrl/ActivationUrl"
import { activeLabelVar } from "modulesCommon/store"
import { useConfirmation } from 'components/Confirmation'
import { ExportUsersList } from 'modules/ExportUsers'

import styles from './user.module.scss'

function userAdapter({ team, role, label, ...rest }: IUser): IUserForm {
  return {
    ...rest,
    team: team?.id,
    role: role?.role_id,
    label: label?.id,
  }
}

const PAGE_SIZE = 10

export const Users: FC = () => {
  const {
    users,
    loading,
    onSort,
    sortState,
    createUser,
    updateUser,
    deleteUser,
    restoreUser,
    activationCode,
    isUserExistInOkta,
  } = useUsers(PAGE_SIZE)

  const { handleEdit } = useAdmin()

  const { onConfirm, element } = useConfirmation(
    deleteUser,
    (message) => (<>Are you sure you want to deactivate user <b>{message}</b> ?</>),
    'Deactivate',
  )

  const { onConfirm: onConfirmRestore, element: elementRestore } = useConfirmation(
    restoreUser,
    (message) => (<>Are you sure you want to restore user <b>{message}</b> ?</>),
    'Restore',
  )

  const onSubmit = useCallback<(data: IUserForm) => Promise<any>>(
    async ({ id, ...user }) => {
      if (typeof id === 'undefined') {
        return createUser(user)
      }
      return updateUser({ id, ...user })
    },
    [createUser, updateUser],
  )

  const columns = useMemo<ColumnsType<IUser>[]>(
    () => [
      {
        sortable: false,
        width: '70px',
        align: 'center',
        render: () => <Icon name='circle-user' className={styles.ava}/>,
      },
      {
        dataIndex: 'first_name',
        title: 'Name',
        key: 'name',
        sortable: true,
        width: '250px',
        align: 'left',
        render: (first_name, record) => `${first_name} ${(record as IUser).last_name}`,
      },
      {
        dataIndex: 'email',
        title: 'Email',
        key: 'email',
        sortable: true,
        width: '250px',
        align: 'left',
      },
      {
        dataIndex: 'team',
        title: 'Team',
        key: 'team',
        sortable: true,
        sorterFunction: (_, direction) => {
          if (direction === 'ASC')
            return (a, b) => a.team.name.localeCompare(b.team.name);
          return (a, b) => b.team.name.localeCompare(a.team.name);
        },
        width: '217px',
        align: 'left',
        render: (team) => team?.name || '',
      },
      {
        dataIndex: 'label',
        title: 'Label',
        key: 'label',
        sortable: true,
        sorterFunction: (_, direction) => {
          if (direction === 'ASC')
            return (a, b) => a.label.name.localeCompare(b.label.name);
          return (a, b) => b.label.name.localeCompare(a.label.name);
        },
        width: '214px',
        align: 'left',
        render: (label) => label?.name || '',
      },
      {
        dataIndex: 'role',
        title: 'Role',
        key: 'role',
        sortable: true,
        sorterFunction: (_, direction) => {
          if (direction === 'ASC')
            return (a, b) => a?.role.role_id.localeCompare(b.role.role_id);
          return (a, b) => b.role.role_id.localeCompare(a.role.role_id);
        },
        width: '170px',
        align: 'left',
        filterSelect: ['Roles'],
        render: (role) => ROLES[role.role_id as keyof typeof ROLES] || role.role_id,
      },
      {
        dataIndex: 'action',
        title: <ExportUsersList />,
        key: 'action',
        sortable: false,
        width: '63px',
        align: 'center',
        render: (el: any, record: any) => record.is_active ? (
          <ActionButton
            handleEdit={() => handleEdit(userAdapter(record as any))}
            handleDelete={() => onConfirm({ data: record.id, message: `${record.first_name} ${record.last_name}` })}
          />
        ) : (
          <IconButton
            message='Restore'
            className={styles.btn}
            icon="trash-can-arrow-up"
            colorType="warning"
            onClick={() => onConfirmRestore({ data: record.id, message: `${record.first_name} ${record.last_name}` })}
          />
        ),
      },
    ],
    [handleEdit, onConfirm, onConfirmRestore],
  )

  const onChangeForm = useCallback(
    (name: string, data: any) => {
      if (name === 'label') {
        activeLabelVar(data.key)
      }
    },
    [],
  )

  const onRow = useCallback((row: any) => ({
      id: row.id, ...(!row.is_active && { className: styles.deactivated }),
  }), [])

  return (
    <>
      <SearchField/>
      <ConditionRender condition={!loading} component={
        <Table
          className={styles.table}
          data={users.data}
          columns={columns}
          rowKey={({ id }) => id}
          onRow={onRow}
          onSort={onSort}
          defaultSort={sortState}
          pagination={false}
        />
      } secondComponent={<SkeletonTable lines={10}/>}
      />

      <Pagination total={users.total} pageSize={PAGE_SIZE}/>
      <ModalForm onSubmit={onSubmit} onChangeForm={onChangeForm}/>

      <ConditionRender condition={isUserExistInOkta || !!activationCode} component={
        <ActivationCode activationCode={activationCode} isUserExistInOkta={isUserExistInOkta}/>
      }/>
      {element}
      {elementRestore}
    </>
  )
}
