import { FC, useCallback, useEffect, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { InputGroup, AsyncSelect, Icon } from '@wmgtech/legato'

import { IArtist, useSearchProduct, IProduct, IGenerateCampaignForm } from 'modules/Campaign'
import { validationAdapter, IFieldError } from 'components/Fields'
import { SearchProductItem } from './SearchProductItem'

export const FormSearchProduct: FC = () => {
  const NAME = 'product'
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController<IGenerateCampaignForm, 'product'>({ name: 'product' })
  const { getValues } = useFormContext()
  const [productSearchTerm,setProductSearchTerm] = useState<string>('')
  // const isExternal = getValues('isExternal')

  const { options, loading, getProducts } = useSearchProduct()

  const handleChange = useCallback(
    (data: IProduct) => {
      onChange(data !== undefined ? data : '')
    },
    [onChange],
  )

  const validation = useCallback(
    (error?: IFieldError) => ({
      // required: isExternal,
      pending: loading,
      ...validationAdapter(error),
    }),
    [loading],
  )

  const handleFilter = useCallback(
    (term: string) => {
      const data: IArtist = getValues('artist')
      getProducts(term, data)
      setProductSearchTerm(term)
      return options
    },
    [getProducts, getValues, options],
  )

  useEffect(() => {
    if (options && !loading) {
      !options.length && onChange('')
    }
  }, [options, loading, onChange])

  return (
    <InputGroup label="Product" validation={validation(errors[NAME] as IFieldError)}>
      <AsyncSelect<IProduct>
        onSelect={handleChange}
        placeholder="Select Product"
        onSearch={handleFilter}
        list={options}
        isClearable
        isLoading={loading}
        getTitle={({ displayTitle }) => displayTitle}
        getElement={({ displayTitle, productType, artistDisplayTitle }) => (
          <SearchProductItem displayTitle={displayTitle} type={productType} artistName={artistDisplayTitle}/>
        )}
        getKey={({ familyId, gpid, type }) => familyId + gpid + type}
        getEmptyElement={() => <p style={{ padding:'0 20px' }}>
          <Icon name="circle-info" prefix="fas" style={{ color: 'red' }}/> Product doesn’t exist -
          contact <a href={`mailto:acid-support@warnermusic.com?subject=Can’t find ACID product [${productSearchTerm}]`}>support</a>
        </p>}
        {...rest}
      />
    </InputGroup>
  )
}
