import { useCallback, useMemo, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { SEARCH_PRODUCTS } from '../constants'
import { IProductSearchInput, IProduct, IArtist } from '../types'

type TPaginatedResponce<T> = {
  items: ReadonlyArray<T>
  total: number
}

type TArgument<T> = {
  input: T
}

export const useSearchProduct = () => {
  const [getProductQuery, { loading, data }] = useLazyQuery<{ searchGcdmProducts: TPaginatedResponce<IProduct> }, TArgument<IProductSearchInput>>(SEARCH_PRODUCTS)

  const [options, setOptions] = useState<ReadonlyArray<IProduct>>()

  const getProducts = useCallback(
    async (searchTerm: string, artist?: IArtist) => {
      if (typeof searchTerm === 'undefined' || searchTerm.length < 2) return setOptions(undefined)
      try {
        const { data } = await getProductQuery({
          variables: {
            input: {
              productName: searchTerm,
              artistName: artist?.artistDisplayTitle ?? '',
              artistOpusId: artist?.artistId ?? '',
              configuration: 'single',
            },
          },
        })
        setOptions(data?.searchGcdmProducts?.items)
      } catch (err) {
        console.warn(err)
      }
    },
    [getProductQuery, setOptions],
  )

  const getProductsDebounce = useRef(debounce(getProducts, 500))

  return {
    loading,
    getProducts: getProductsDebounce.current,
    options,
  }
}
