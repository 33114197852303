import { createElement, useCallback, useMemo, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client'
import { IUser, IUserForm } from 'modules/Admin'
import {
  CREATE_USER,
  DEFAULT_SORT_DIRECTION,
  DEFAULT_SORT_FIELD,
  DELETE_USER,
  GET_INTERNAL_DOMAINS,
  LIST_USERS,
  RESTORE_USER,
  SORT_DIRECTION,
  SORT_FIELD_PARAM,
  UPDATE_USER,
} from '../constants'
import compose from "lodash/fp/compose";
import { pageParams, searchTerm, sortParams } from "../../Campaign";
import { IUserListInput } from "../types";
import { TPaginatedResponse } from "../../../shared/types";
import { growl, TTableSortConfig, TTableSortDirection } from "@wmgtech/legato";
import { formatOktaUserData } from "../helpers";
import { useCreateOktaUser } from "./createOktaUser";
import { IUserManagement } from "../../../pages/Admin/types";
import { useAssignUserOktaGroup } from "./assignUserOktaGroup";
import { Toast } from "../../../components/Toast";

interface UserRoleInput {
  expire_at?: string
  role_id: string
}

interface IUsersCreate {
  email: string
  first_name: string
  label_id: string
  last_name: string
  role?: UserRoleInput
  team_id?: string
}

interface IUserUpdate {
  email: string
  first_name?: string
  id: string
  label_id?: string
  last_name?: string
  role?: UserRoleInput
  team_id?: string
  username?: string
}


export const useUsers = (limit?: number) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [internalDomains, setInternalDomains] = useState<any[]>([])
  const [activationCode, setShowActivation] = useState('')
  const [isUserExistInOkta, setUserExistInOkta] = useState(false)

  const getUserListVariables = useMemo<IUserListInput>(
    () => compose(
      pageParams(searchParams),
      sortParams(searchParams),
      searchTerm(searchParams),
    )({
      input: {
        limit: limit || 10,
        offset: 0,
        sortOrder: {
          direction: DEFAULT_SORT_DIRECTION,
          field: DEFAULT_SORT_FIELD,
        },
      },
    }),
    [limit, searchParams],
  )

  const { data: users, loading } = useQuery<{ listUsers: TPaginatedResponse<IUser> }, IUserListInput>(LIST_USERS, {
    variables: getUserListVariables,
    skip: typeof limit === 'undefined',
  })

  const handleSort = useCallback<(field: string) => void>(
    (key: string) => {
      const currentSortField = searchParams.get(SORT_FIELD_PARAM)
      const currentSortDirection = searchParams.get(SORT_DIRECTION)
      if (currentSortField === key && currentSortDirection === 'ASC') {
        searchParams.set(SORT_DIRECTION, 'DESC')
      } else if (currentSortField === key && currentSortDirection === 'DESC') {
        searchParams.set(SORT_DIRECTION, 'ASC')
      } else {
        searchParams.set(SORT_FIELD_PARAM, key)
        searchParams.set(SORT_DIRECTION, DEFAULT_SORT_DIRECTION)
      }
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams],
  )

  const sortState = useMemo<TTableSortConfig>(
    () => ({
      key: searchParams.get(SORT_FIELD_PARAM) || DEFAULT_SORT_FIELD,
      direction: (searchParams.get(SORT_DIRECTION) || DEFAULT_SORT_DIRECTION) as TTableSortDirection,
    }),
    [searchParams],
  )


  const [createUserMutation] = useMutation<{ createUser: IUser }, { input: IUsersCreate }>(CREATE_USER,{
    update(cache) {
      cache.modify({
        fields: {
          listUsers() {},
        },
      })
    },
  })

  const [deleteUserMutation] = useMutation<{ deleteUser: { data: any } }>(DELETE_USER, {
    update(cache) {
      cache.modify({
        fields: {
          listUsers() {},
        },
      })
    },
  })

  const [restoreUserMutation] = useMutation<{ restoreUser: { data: any } }>(RESTORE_USER, {
    update(cache) {
      cache.modify({
        fields: {
          listUsers() {},
        },
      })
    },
  })

  const [updateUserMutation] = useMutation<{ updateUser: { data: IUser } }, { input: IUserUpdate }>(UPDATE_USER)
  const { mutate: assignOktaUser, loading: isAssignOktaLoading } = useAssignUserOktaGroup()

  const { loading: isInternalDomainLoading, error: internalDomainError } = useQuery(GET_INTERNAL_DOMAINS, {
    onCompleted: (data) => {
      setInternalDomains([...(data?.getInternalDomains || [])])
    },
  })

  const domainsRegexp = useMemo(() => {
    const domains = internalDomains.join('|')
    return new RegExp(`@(${domains})\\s*$`, 'i')
  }, [internalDomains])

  const { mutate: createOktaUser, loading: isOktaCreationLoading } = useCreateOktaUser()


  const onCreateOktaUser = useCallback(
    async (values: IUserManagement) => {
      const { data } = await createOktaUser({ variables: { input: formatOktaUserData(values) } })

      if (data.createOktaUser?.profile?.email) {
        return data.createOktaUser
      } else {
        return
      }
    },
    [createOktaUser],
  )


  const onAssignCreateUser = useCallback(
    async (email: string) => {
      const { data } = await assignOktaUser({
        variables: { email },
      })

      return data
    },
    [assignOktaUser],
  )

  const createNewUserHandler = useCallback(async ({ email, first_name, last_name, label, team, role }: Omit<IUserForm, 'id'>) => {
    try {
      const newUser = await createUserMutation({
        variables: {
          input: {
            email,
            first_name,
            last_name,
            label_id: label,
            team_id: team,
            role: {
              role_id: role,
            },
          },
        },
      })
      return newUser
    } catch (e: any) {
      growl.error({
        message: createElement(Toast, { message: e?.message, title: 'Error' }),
        containerStyle: 'tint',
        colorType: 'danger',
        icon: 'check',
      }, { autoClose: 3000 })
    }
  }, [createUserMutation])

  const createNewUser = useCallback(
    async ({ email, first_name, last_name, label, team, role }: Omit<IUserForm, 'id'>) => {
      const newUser = await createNewUserHandler({
        email,
        first_name,
        last_name,
        label,
        team,
        role,
      })
      if (newUser) {
        await onAssignCreateUser(email)
        growl.success({
          message: createElement(Toast, { message: `${first_name} | ${last_name} has been created successfully` }),
          containerStyle: 'tint',
          colorType: 'success',
          icon: 'check',
        }, { autoClose: 3000 })

        return newUser
      }
    },
    [onAssignCreateUser, createNewUserHandler],
  )


  const createUser = useCallback(
    async ({ email, first_name, last_name, label, team, role }: Omit<IUserForm, 'id'>) => {

      const isUserExternal = !!email && !email?.match(domainsRegexp)

      if (isUserExternal) {
        const oktaUserData = await onCreateOktaUser({ email, first_name, last_name, label, team, role })

        if (oktaUserData) {
          const userData = await createNewUser({ email, first_name, last_name, label, team, role })

          if (userData && oktaUserData?.activation) {
            setShowActivation(oktaUserData?.activation)
          } else if (userData) {
            setUserExistInOkta(true)
          }
        }

      } else {
        return createNewUser({ email, first_name, last_name, label, team, role })
      }
    },
    [domainsRegexp, onCreateOktaUser, createNewUser],
  )

  const restoreUser = useCallback<(id: string) => void>(
    async (id) => {
    await restoreUserMutation({
      variables: {
        id,
      },
    })

   if (users?.listUsers?.data) {
     const user = users?.listUsers?.data?.find((user) => user.id === id)
     if (user) {
       growl.success({
         message: createElement(Toast, { message: `Your ID for ${user.first_name} | ${user.last_name} has been successfully restored` }),
         containerStyle: 'tint',
         colorType: 'success',
         icon: 'check',
       }, { autoClose: 3000 })
     }
   }},
    [restoreUserMutation, users],
  )

  const deleteUser = useCallback<(id: string) => void>(
      async (id) => {
        await deleteUserMutation({
          variables: {
            id,
          },
        })

        if (users?.listUsers?.data) {
          const user = users?.listUsers?.data?.find((user) => user.id === id)
          if (user) {
            growl.success({
              message: createElement(Toast, { message: `Your ID for ${user.first_name} | ${user.last_name} has been successfully deleted` }),
              containerStyle: 'tint',
              colorType: 'success',
              icon: 'check',
            }, { autoClose: 3000 })
          }
        }

      },
      [deleteUserMutation, users],
  )

  const updateUser = useCallback(
    async ({ team, label, role, email, first_name, last_name, id }: IUserForm) => {
      await updateUserMutation({
        variables: {
          input: {
            id,
            email,
            first_name,
            last_name,
            team_id: team,
            role: {
              role_id: role,
            },
            label_id: label,
          },
        },
      })
      growl.success({
        message: createElement(Toast, { message: `${first_name} | ${last_name} has been saved successfully` }),
        containerStyle: 'tint',
        colorType: 'success',
        icon: 'check',
      }, { autoClose: 3000 })
    },
    [updateUserMutation],
  )


  return {
    users: useMemo<TPaginatedResponse<IUser>>(
      () => ({
        data: users?.listUsers?.data ?? [],
        total: users?.listUsers?.total ?? 0,
      }),
      [users],
    ),
    loading,
    onSort: handleSort,
    sortState,
    createUser,
    deleteUser,
    restoreUser,
    updateUser,
    activationCode,
    isUserExistInOkta,
  }
}
