import { FC, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { InputGroup, AsyncSelect } from '@wmgtech/legato'
import compact from 'lodash/fp/compact'
 
import { useSearchArtist, IArtist } from 'modules/Campaign'
import { validationAdapter, IFieldError } from 'components/Fields'
import { SearchItem } from './SearchItem'

export const FormSearchArtist: FC<{ isRequired?: boolean }> = ({ isRequired }) => {
  const NAME = 'artist'
  const { field: { onChange, ref, ...rest }, formState: { errors } } = useController({ name: NAME })

  const { options, loading, getArtist } = useSearchArtist()
  
  const handleChange = useCallback(
    (data: IArtist) => {
        onChange(data !== undefined ? data : '')
    },
    [onChange],
  )
    
  const validation = useCallback(
    (error?: IFieldError) => ({
      required: isRequired,
      pending: loading,
      ...validationAdapter(error),
    }),
    [loading, isRequired],
  )

  const handleFilter = useCallback(
    (term: string) => {
      getArtist(term)
    },
    [getArtist],
  )

  return (
    <InputGroup label="Artist" validation={validation(errors[NAME] as IFieldError)}>
      <AsyncSelect<IArtist>
        onSelect={handleChange}
        isClearable
        placeholder="Select Artist"
        onSearch={handleFilter}
        isLoading={loading}
        list={options}
        getKey={({ artistId }) => artistId}
        getTitle={({ artistDisplayTitle }) => artistDisplayTitle}
        getElement={(artist) => (
          <SearchItem
            title={artist.artistDisplayTitle}
            subtitle={compact([artist.genre, artist.country, artist.company]).join(' | ')}
          />)
        }
        {...rest}
      />
    </InputGroup>
  )
}
